import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";

interface Props {
    children: JSX.Element;
}
export default function AdminRoute({ children }: Props) {
    const { isAdmin } = useAuth();
    if (!isAdmin) {
        console.log("%cShowing Public Route", "color:cyan");
        return <Navigate to="/auth" replace />;
    }

    console.log("%cShowing Public Route", "color:cyan");
    return children;
}
