import { app } from "../firebase/firebase";
import { createContext, useContext, useEffect, useState } from "react";
import {
    getAuth,
    onAuthStateChanged,
    User as FirebaseUser,
} from "firebase/auth";
import { getUserClaims } from "../functions/admin";

const isDev = process.env.NODE_ENV === "development";

type User = FirebaseUser | null;
type ContextState = {
    user: User;
    isAdmin: boolean;
    setIsAdmin: (b: boolean) => void;
};

const FirebaseAuthContext = createContext<ContextState | undefined>(undefined);

interface Props {
    children: JSX.Element;
}
function AuthProvider({ children }: Props) {
    const [user, setUser] = useState<User>(null);
    const [isAdmin, setIsAdmin] = useState(true);
    const value = { user, isAdmin, setIsAdmin };
    const auth = getAuth(app);

    useEffect(() => {
        async function checkAdmin(uid: string) {
            const { data } = await getUserClaims(uid);
            if (isDev) {
                console.log("claims!", data);
            }
            if (data.claims?.admin) {
                if (isDev) {
                    console.log("%cUser is an Admin!", "color:hotpink");
                }
                setIsAdmin(true);
            } else {
                if (isDev) {
                    console.log("%cUser is not an Admin!", "color:cyan");
                }
                setIsAdmin(false);
            }
        }
        onAuthStateChanged(auth, (user) => {
            if (user?.uid) {
                checkAdmin(user?.uid);
            }
            setUser(user);
        });
        return () => setUser(null);
    }, [auth]);
    // useEffect(() => {
    //     const unsubscribe = auth.onAuthStateChanged(setUser);
    //     return unsubscribe;
    // }, [auth]);

    return (
        <FirebaseAuthContext.Provider value={value}>
            {children}
        </FirebaseAuthContext.Provider>
    );
}

function useAuth() {
    const context = useContext(FirebaseAuthContext);
    if (context === undefined) {
        throw new Error(
            "useFirebaseAuth must be used within a FirebaseAuthProvider"
        );
    }
    return context;
}

export { AuthProvider, useAuth };
