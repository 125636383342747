import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../firebase/firebase";

// const isDev = process.env.NODE_ENV === "development";

export async function getShowList() {
    try {
        const listRef = ref(storage, "gs://show-slime-2810f.appspot.com");
        const resp = await listAll(listRef);
        const episodes: any[] = [];
        for (let i = 0; i < resp.prefixes.length; i++) {
            const res = await listAll(resp.prefixes[i]);
            episodes.push({ show: resp.prefixes[i].name, episodes: res.items });
        }
        return Promise.resolve(episodes);
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function getEpisode2(
    episodePath: string,
    setDownloadProgress: (p: number) => void,
    setCurrentDownloadTask: (task: XMLHttpRequest | undefined) => void
): Promise<void> {
    let cancelled = false;

    try {
        const pathReference = ref(
            storage,
            `gs://show-slime-2810f.appspot.com/${episodePath}`
        );

        const url = await getDownloadURL(pathReference);

        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";

        xhr.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setDownloadProgress(Math.floor(percentComplete));
            }
        });

        setCurrentDownloadTask(xhr);

        xhr.onload = () => {
            if (!cancelled && xhr.status === 200) {
                const blob = new Blob([xhr.response], {
                    type: xhr.getResponseHeader("content-type") || undefined,
                });

                const blobUrl = URL.createObjectURL(blob);

                const anchor = document.createElement("a");
                anchor.href = blobUrl;
                anchor.download = episodePath.split("/")[1];
                anchor.style.display = "none";
                document.body.appendChild(anchor);

                anchor.click();

                // Clean up after download
                document.body.removeChild(anchor);
                URL.revokeObjectURL(blobUrl);

                // Reset download progress and current download task
                // setDownloadProgress(0);
                // setCurrentDownloadTask(undefined);
            }
        };

        xhr.onerror = () => {
            cancelled = true;
        };

        xhr.send();

        return new Promise<void>((resolve, reject) => {
            const cancelDownload = () => {
                cancelled = true;
                xhr.abort();
                reject(new Error("Download cancelled by user."));
            };

            // Resolve the promise when the download completes
            xhr.addEventListener("load", () => {
                if (!cancelled) {
                    resolve();
                }
            });

            return cancelDownload;
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
export async function getEpisode(
    episodePath: string,
    setDownloadProgress: (p: number) => void,
    setCurrentDownloadTask: (task: XMLHttpRequest | undefined) => void
): Promise<void> {
    let cancelled = false;

    try {
        const pathReference = ref(
            storage,
            `gs://show-slime-2810f.appspot.com/${episodePath}`
        );

        const url = await getDownloadURL(pathReference);

        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";

        xhr.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setDownloadProgress(Math.floor(percentComplete));
            }
        });

        setCurrentDownloadTask(xhr);

        xhr.onload = () => {
            if (!cancelled && xhr.status === 200) {
                const blob = new Blob([xhr.response], {
                    type: xhr.getResponseHeader("content-type") || undefined,
                });

                const blobUrl = URL.createObjectURL(blob);

                const anchor = document.createElement("a");
                anchor.href = blobUrl;
                anchor.download = episodePath.split("/")[1];
                anchor.style.display = "none";
                document.body.appendChild(anchor);

                // Trigger the download
                anchor.click();

                // Clean up after download
                document.body.removeChild(anchor);
                URL.revokeObjectURL(blobUrl);

                // Reset download progress and current download task
                setDownloadProgress(0);
                setCurrentDownloadTask(undefined);
            }
        };

        xhr.onerror = () => {
            cancelled = true;
        };

        xhr.send();

        return new Promise<void>((resolve, reject) => {
            const cancelDownload = () => {
                cancelled = true;
                xhr.abort();
                reject(new Error("Download cancelled by user."));
            };

            // Resolve the promise when the download completes
            xhr.addEventListener("load", () => {
                if (!cancelled) {
                    resolve();
                }
            });

            return cancelDownload;
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
