import { useState } from "react";
import { appleAuthProvider } from "../firebase/firebase";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import {
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
} from "firebase/auth";
import ErrorAlert from "./ErrorAlert";
import Header from "./Header";

export default function Auth() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [signUp, setSignUp] = useState(false);

    function handleAuth(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (signUp) {
            handleSignUp();
        } else {
            handleSignIn();
        }
    }
    async function handleSignIn() {
        try {
            setError("");
            setLoading(true);
            const auth = getAuth();
            const resp = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            console.log("user", resp.user);
            // setUser(resp.user);
            setLoading(false);
        } catch (error: any) {
            console.warn(error);
            console.warn(error.message);
            setError("Bad username or password");
            setLoading(false);
        }
    }
    async function handleSignUp() {
        try {
            setLoading(true);
            const auth = getAuth();
            const resp = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            console.log("user", resp.user);
            // setUser(resp.user);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            console.warn(error);
            console.warn(error.message);
            console.warn(error.code);
            if (error.code && error.code === "email-already-in-use") {
                setError("Email already in use");
            }
            setError(error.message);
        }
        // const provider = new GoogleAuthProvider();
        // const result = await signInWithPopup(auth, provider);
        // console.log(result);
    }

    async function handleSignInWithApple() {
        try {
            const auth = getAuth();
            const result = await signInWithPopup(auth, appleAuthProvider);
            console.log("result", result);
        } catch (error: any) {
            console.log(error);
            if (error?.code === "auth/popup-closed-by-user") {
                return;
            } else if (error?.code) {
                setError(error.code);
            } else {
                setError(error.message);
            }
        }
    }
    return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <Header signUp={signUp} />
            <div className="container mt-3 mt-sm-5" style={{ maxWidth: 400 }}>
                <Form className="w-100" onSubmit={handleAuth}>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="bruce@showslime.com"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            value={email}
                        />
                    </Form.Group>

                    <Form.Label htmlFor="password">Password</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            id="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Sh@wSl!m3"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            value={password}
                        />
                        <InputGroup.Text>
                            <i
                                className={`bi ${
                                    showPassword ? "bi-eye-slash" : "bi-eye"
                                } `}
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: "pointer" }}
                            />
                        </InputGroup.Text>
                    </InputGroup>
                    <Button
                        className="w-100"
                        disabled={
                            loading || email.length < 4 || password.length === 0
                        }
                        type="submit"
                    >
                        {loading ? (
                            <Spinner size="sm" />
                        ) : (
                            <i className="bi bi-envelope-fill" />
                        )}
                        <span className="ms-2">
                            Sign {signUp ? "Up" : "In"} With Email
                        </span>
                    </Button>
                    <Button
                        className="w-100 mt-3"
                        disabled={loading}
                        onClick={handleSignInWithApple}
                        type="button"
                        variant="light"
                    >
                        <i className="bi bi-apple me-2" />
                        Continue with Apple
                    </Button>
                    <Button
                        className="text-decoration-none w-100 mt-2"
                        disabled={loading}
                        onClick={() => setSignUp(!signUp)}
                        type="button"
                        variant="link"
                    >
                        {signUp ? "Existing User?" : "New User?"}
                    </Button>
                </Form>
                {error && <ErrorAlert message={error} />}
            </div>
        </div>
    );
}
