// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { OAuthProvider, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB3F9OZr93syLcfhL9OJ070QQFBnfm93IQ",
    // authDomain: "show-slime-2810f.firebaseapp.com",
    authDomain: "showslime.abts.io",
    projectId: "show-slime-2810f",
    storageBucket: "show-slime-2810f.appspot.com",
    messagingSenderId: "959138617093",
    appId: "1:959138617093:web:63b9be09caf1b69ed9496c",
    measurementId: "G-5EBRGYQGL9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app, "us-west1");
const storage = getStorage(app);
const appleAuthProvider = new OAuthProvider("apple.com");
const googleAuthProvider = new GoogleAuthProvider();
const analytics = getAnalytics(app);

export {
    analytics,
    appleAuthProvider,
    app,
    functions,
    googleAuthProvider,
    db,
    storage,
};
