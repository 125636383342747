import { Accordion } from "react-bootstrap";
import EpisodeList from "./EpisodeList";

interface Props {
    episodes: string[];
    shows: string[];
    showWatched: boolean;
    watchedEpisodes: string[];
    setWatchedEpisodes: (episodes: string[]) => void;
    userId: string;
}
export default function ShowList({
    episodes,
    shows,
    showWatched,
    userId,
    watchedEpisodes,
    setWatchedEpisodes,
}: Props) {
    function renderShows() {
        return shows.map((show, i) => (
            <Accordion.Item key={show} eventKey={`${i}`}>
                <Accordion.Header>{show}</Accordion.Header>
                <Accordion.Body className="w-100">
                    <EpisodeList
                        show={show}
                        showWatched={showWatched}
                        episodes={episodes}
                        watchedEpisodes={watchedEpisodes}
                        setWatchedEpisodes={setWatchedEpisodes}
                        userId={userId}
                    />
                </Accordion.Body>
            </Accordion.Item>
        ));
    }

    return (
        <div
            className="container d-flex justify-content-center w-100"
            style={{ maxWidth: 640 }}
        >
            <Accordion className="w-100">{renderShows()}</Accordion>
        </div>
    );
}
