import { useEffect, useMemo, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { createDbUser, getDbUser } from "./functions";
import { getAuth } from "firebase/auth";
import { getShowList } from "./functions/storage";
import { StorageReference } from "firebase/storage";
import { useAuth } from "./contexts/AuthProvider";
import Header from "./components/Header";
import HelpModal from "./components/HelpModal";
import NoShows from "./components/NoShows";
import ShowList from "./components/ShowList";
import { useNavigate } from "react-router-dom";

const isDev = process.env.NODE_ENV === "development";

function App() {
    // const [fileToDelete, setFileToDelete] = useState("");
    // const [showDeleteModal, setShowDeleteModal] = useState(false);
    // const [user, setUser] = useState<any>();
    const [episodeRefs, setEpisodeRefs] = useState<
        { show: string; episodes: StorageReference[] }[]
    >([]);
    const [loading, setLoading] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showWatched, setShowWatched] = useState(false);
    const [watchedEpisodes, setWatchedEpisodes] = useState<string[]>([]);
    const { user, isAdmin } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        async function handleUser(id: string) {
            try {
                setLoading(true);
                if (isDev) {
                    console.log("%c🐕 Fetching user from DB", "color:cyan");
                }
                const getResp = await getDbUser(id);
                if (isDev) {
                    console.log("%c🦴 User Found!", "color:lime");
                }
                if (getResp) {
                    setWatchedEpisodes(getResp.watchedEpisodes);
                } else {
                    if (isDev) {
                        console.log(
                            "%c🤷🏽 No user in DB. Creating new one...",
                            "color:cyan"
                        );
                    }
                    await createDbUser(id);

                    if (isDev) {
                        console.log("%c👶 User Created!", "color:lime");
                    }
                }
                const listShowsResp = await getShowList();
                setEpisodeRefs(listShowsResp);
                setLoading(false);
            } catch (error: any) {
                if (isDev) {
                    console.warn(error);
                    console.log(error.code);
                }
                setLoading(false);
            }
        }
        if (user?.uid) {
            handleUser(user.uid);
        }
    }, [user]);

    const { episodes, shows, unwatched, watched } = useMemo(() => {
        const episodePaths = episodeRefs.flatMap((item) =>
            item.episodes.map((episode) => episode.fullPath)
        );
        const shows = episodeRefs.map((item) => item.show);
        const unwatched = episodePaths.filter(
            (path) => !watchedEpisodes?.includes(path)
        );
        const watched = episodePaths.filter((path) =>
            watchedEpisodes?.includes(path)
        );
        return {
            episodes: showWatched ? watched : unwatched,
            shows,
            unwatched,
            watched,
        };
    }, [episodeRefs, showWatched, watchedEpisodes]);

    async function handleSignOut() {
        const auth = getAuth();
        auth.signOut();
    }

    return (
        <>
            <div
                className="d-flex flex-column align-items-center w-100"
                style={{
                    height: loading ? "100vh" : "auto",
                    position: "relative",
                }}
            >
                <Header />
                {user?.uid && loading && (
                    <div className="d-flex flex-column align-items-center mt-5">
                        <Spinner
                            animation="border"
                            role="status"
                            variant="secondary"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <small className="text-body-secondary mt-2">
                            Loading Shows...
                        </small>
                    </div>
                )}
                {user?.uid && !loading && shows.length === 0 && (
                    <NoShows showWatched={showWatched} />
                )}
                {user?.uid && !loading && shows.length > 0 && (
                    <div className="d-flex justify-content-center my-3 w-100">
                        <ShowList
                            episodes={episodes}
                            shows={shows}
                            showWatched={showWatched}
                            userId={user.uid}
                            watchedEpisodes={watchedEpisodes}
                            setWatchedEpisodes={setWatchedEpisodes}
                        />
                    </div>
                )}
                {!loading && showWatched && unwatched.length > 0 && (
                    <Button
                        className="text-decoration-none"
                        onClick={() => setShowWatched(!showWatched)}
                        variant="link"
                    >
                        Show Unwatched
                    </Button>
                )}
                {!loading && !showWatched && watched.length > 0 && (
                    <Button
                        className="text-decoration-none"
                        onClick={() => setShowWatched(!showWatched)}
                        variant="link"
                    >
                        Show Watched
                    </Button>
                )}
            </div>
            <div
                className="d-flex align-items-center"
                style={{ position: "absolute", top: 8, left: 0 }}
            ></div>
            <div
                className="d-flex align-items-center"
                style={{ position: "absolute", top: 8, right: 0 }}
            >
                {isAdmin && (
                    <Button
                        className=""
                        onClick={() => navigate("/admin")}
                        size="sm"
                        variant="danger"
                    >
                        Admin
                    </Button>
                )} 
                {user && (
                    <Button
                        className="ms-3"
                        onClick={() => handleSignOut()}
                        size="sm"
                        variant="primary"
                    >
                        Sign Out
                    </Button>
                )}
                <Button
                    variant="link"
                    onClick={() => {
                        setShowHelpModal(true);
                    }}
                >
                    <i className="bi bi-question-circle fs-3" />
                </Button>
            </div>
            {/* <DeleteModal
                files={files}
                setFiles={setFiles}
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                fileToDelete={fileToDelete}
            /> */}
            <HelpModal show={showHelpModal} setShow={setShowHelpModal} />
        </>
    );
}

export default App;
