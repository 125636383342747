import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();
    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center vh-100"
            id="error-page"
        >
            <div className="" style={{ fontSize: 72 }}>
                🍗
            </div>
            <div className="display-4 mt-3">There's nothing here</div>
            <Button
                className="text-decoration-none mt-3"
                variant="link"
                onClick={() => navigate("/")}
            >
                Go Home
            </Button>
        </div>
    );
}
