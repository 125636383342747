import { collection, deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
export async function getDbUser(userId: string) {
    try {
        const collectionRef = collection(db, "users");
        const docRef = doc(collectionRef, userId);
        const resp = await getDoc(docRef);
        return Promise.resolve(resp.data());
    } catch (error) {
        return Promise.reject(error);
    }
}
export async function createDbUser(userId: string) {
    try {
        const date = new Date();
        const collectionRef = collection(db, "users");
        const docRef = doc(collectionRef, userId);
        const payload = {
            userId,
            watchedEpisodes: [],
            createdAt: date,
            updatedAt: date,
        };
        await setDoc(docRef, payload);
        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function updateUser(userId: string, updates: Record<string, any>) {
    try {
        const date = new Date();
        const collectionRef = collection(db, "users");
        const docRef = doc(collectionRef, userId);
        const payload = {
            ...updates,
            updatedAt: date,
        };
        await setDoc(docRef, payload, { merge: true });
        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function deleteUserData(userId: string) {
    try {
        await deleteDoc(doc(db, "users", userId));
        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
}
