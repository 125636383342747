export function getRando() {
    const titles = [
        { emoji: "🍿", title: "show slime" },
        { emoji: "🐓", title: "huli huli" },
        { emoji: "📽️", title: "movie me" },
        { emoji: "🎬", title: "the director's fut" },
        { emoji: "🏴‍☠️", title: "the aloha bay" },
        { emoji: "📺", title: "channel 13" },
        { emoji: "🌭", title: "wiener vision" },
    ];
    return titles[Math.floor(Math.random() * titles.length)];
}
