import { lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import App from "../App";
import Auth from "../components/Auth";
import AdminRoute from "./AdminRoute";
import NotFound from "./NotFound";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const Admin = lazy(() => import("../components/admin/Admin"));

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <Suspense>
                                <App />
                            </Suspense>
                        </PrivateRoute>
                    }
                    errorElement={<NotFound />}
                />
                <Route
                    path="/admin"
                    element={
                        <AdminRoute>
                            <Suspense>
                                <Admin />
                            </Suspense>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/auth"
                    element={
                        <PublicRoute>
                            <Suspense>
                                <Auth />
                            </Suspense>
                        </PublicRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}
