import { useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import { useAuth } from "../contexts/AuthProvider";
import { getRando } from "../functions/rando";
import FontFaceObserver from "fontfaceobserver";

interface Props {
    signUp?: boolean;
    text?: string;
}
export default function Header({ signUp = false, text }: Props) {
    const { width } = useWindowSize();
    const [fontLoaded, setFontLoaded] = useState(false);
    const [rando, setRando] = useState<{ emoji: string; title: string }>();
    const { user } = useAuth();

    useEffect(() => {
        setRando(getRando());
        const font = new FontFaceObserver("Codystar");

        font.load(null, 10000).then(() => {
            setFontLoaded(true);
        });
    }, []);

    return (
        <div className="d-flex flex-column align-items-center mt-5 mt-sm-5">
            <div style={{ fontSize: width < 576 ? 64 : 96 }}>
                {user?.uid ? rando?.emoji : "🍿"}
            </div>
            {fontLoaded && (
                <h1
                    className="display-4 text-warning"
                    style={{ fontFamily: "Codystar" }}
                >
                    {text
                        ? text
                        : user?.uid
                        ? rando?.title
                        : signUp
                        ? "Sign Up"
                        : "Sign In"}
                </h1>
            )}
        </div>
    );
}
