import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";

interface Props {
    children: JSX.Element;
}
export default function PrivateRoute({ children }: Props) {
    const { user } = useAuth();
    if (!user?.uid) {
        console.log(
            "%c🚦 Redirecting to '/auth' from private route",
            "color:red"
        );
        return <Navigate to="/auth" replace />;
    }

    console.log("%cShowing Private Route", "color:cyan");
    return children;
}
