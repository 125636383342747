import {
    // DocumentData,
    // Query,
    collection,
    getDocs,
    limit,
    query,
    // startAfter,
    // where,
} from "firebase/firestore";
import { db, functions } from "../firebase/firebase";
import { httpsCallable } from "firebase/functions";

const isDev = process.env.NODE_ENV === "development";

export async function deleteGoogleAuthUser(email: string) {
    try {
        if (!email) {
            throw new Error("No user email!");
        }
        const deleteUser = httpsCallable(functions, "deleteUser");
        const resp = await deleteUser({ email });
        if (isDev) {
            console.log(
                "%cDelete Google Auth User Response",
                "color:orange",
                resp
            );
        }
        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function disableGoogleAuthUser(email: string) {
    try {
        const disableUser = httpsCallable(functions, "disableUser");
        const resp = await disableUser({ email });
        if (isDev) {
            console.log("%cDisable Google User Response", "color:orange", resp);
        }
        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function enableGoogleAuthUser(email: string) {
    try {
        const enableUser = httpsCallable(functions, "enableUser");
        const resp = await enableUser({ email });
        if (isDev) {
            console.log("%cEnable Google User Response", "color:orange", resp);
        }
        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function findUserByEmail(email: string) {
    try {
        const findUserByEmailFn = httpsCallable(functions, "getUserInfo");
        const resp: any = await findUserByEmailFn({ email });
        return Promise.resolve([resp.data]);
    } catch (error: any) {
        if (error.message && error.message === "not-found") {
            return Promise.reject(new Error("User not found"));
        } else {
            if (isDev) {
                console.error("Failed to find user by email.");
            }
        }
        return Promise.reject(error);
    }
}

export async function getAllUsers(
    nextPageToken: string | null | undefined = undefined
) {
    try {
        const getAllUsersFn = httpsCallable(functions, "getAllUsersWithClaims");
        const resp: any = await getAllUsersFn({ nextPageToken });
        // const { nextPageToken, users } = resp.data;

        return Promise.resolve(resp.data.users);
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function getAllUsers2() {
    try {
        const q = query(collection(db, "users"), limit(50));
        const querySnapshot = await getDocs(q);
        const users: Record<string, any>[] = [];
        querySnapshot.forEach((doc) => {
            const user = doc.data() as Record<string, any>;
            users.push(user);
        });

        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function getUsersWithClaim(claim: string) {
    try {
        const getUsersWithClaimFn = httpsCallable(
            functions,
            "getUsersWithClaim"
        );
        const resp: any = await getUsersWithClaimFn({
            claim: claim,
        });
        if (isDev) {
            console.log("getUsersWithClaim resp", resp);
        }
        return Promise.resolve(resp.data.users);
    } catch (error) {
        return Promise.reject(error);
    }
}
export async function getUserClaims(uid: string) {
    try {
        const getUserClaimsFn = httpsCallable(functions, "getUserClaims");
        const resp: any = await getUserClaimsFn({ uid });
        return Promise.resolve(resp);
    } catch (error: any) {
        if (error.message && error.message === "not-found") {
            return Promise.reject(new Error("User not found"));
        } else {
            if (isDev) {
                console.error("Failed to find user by email.");
            }
        }
        return Promise.reject(error);
    }
}

export async function setCustomClaim(uid: string, claim: string) {
    try {
        const setCustomClaimFn = httpsCallable(functions, "setCustomClaim");
        await setCustomClaimFn({
            uid: uid,
            claim: claim,
        });
        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
}
