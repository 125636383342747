interface Props {
    showWatched: boolean;
}
export default function NoShows({  showWatched }: Props) {
    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
        >
            <div style={{ fontSize: 56 }}>😱</div>
            <h1 className="lead">
                No {!showWatched ? "Watched Shows" : "Shows to Watch"}
            </h1>
        </div>
    );
}
