import { Alert } from "react-bootstrap";

interface Props {
    message: string;
    dismissible?: boolean;
    variant?: string;
}
export default function ErrorAlert({
    message,
    dismissible = true,
    variant = "danger",
}: Props) {
    return (
        // <small>
            <Alert dismissible={dismissible} variant={variant}>
                {message}
            </Alert>
        // </small>
    );
}
