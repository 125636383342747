import { Button, Modal } from "react-bootstrap";
import localFiles from "../images/local_files.jpeg";
import selectFile from "../images/select_file.jpeg";
import castIcon from "../images/cast_icon.jpeg";
import screenMirror from "../images/screen_mirror.jpeg";

interface Props {
    show: boolean;
    setShow: (b: boolean) => void;
}
export default function DeleteModal({ show, setShow }: Props) {
    function handleClose() {
        setShow(false);
    }
    return (
        <Modal fullscreen show={show} onHide={handleClose}>
            <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center w-100">
                <div className="w-100" style={{ maxWidth: 800 }}>
                    {/* <h1 className="display-4 text-center">Fuck you</h1> */}
                    <h1 className="text-center">
                        How to watch shows downloaded from Show Slime on your
                        iPhone or Apple TV
                    </h1>
                    <ol className="mt-3">
                        <li>
                            <Button
                                variant="link"
                                className="text-decoration-none p-0"
                            >
                                <a
                                    className="text-decoration-none"
                                    href="https://www.videolan.org/vlc/download-ios.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Install VLC for iOS on your iPhone
                                </a>
                            </Button>
                        </li>
                        <li>Open VLC</li>
                        <li>
                            Tap <span className="fw-bold">Local files</span>
                        </li>
                        <img
                            className="my-2"
                            src={localFiles}
                            alt="local files"
                            height="auto"
                            width="280px"
                        />
                        <li>Find the show you want to play and tap on it</li>
                        <img
                            className="my-2"
                            src={selectFile}
                            alt="select files"
                            height="auto"
                            width="280px"
                        />
                        <li>
                            You can watch the show now on your phone or watch on
                            your Apple TV by continuing:
                        </li>
                        <li>Make sure your Apple TV is on</li>
                        <li>
                            Click the <span>Cast</span> icon (top right)
                        </li>
                        <img
                            className="my-2"
                            src={castIcon}
                            alt="cast icon"
                            height="auto"
                            width="280px"
                        />
                        <li>Tap on your Apple TV</li>
                        <li>
                            Slide down from the top right to show the Control
                            Center
                        </li>
                        <li>Tap the Screen Mirroring icon</li>
                        <img
                            className="my-2"
                            src={screenMirror}
                            alt="cast icon"
                            height="auto"
                            width="280px"
                        />
                        <li>Tap on your Apple TV</li>
                        <li>Slide up to close Control Center</li>
                        <li>Go Back to VLC and tap the play button</li>
                        <li>And Bob's your uncle!</li>
                    </ol>
                    <p>
                        🙋🏽‍♀️ <strong>Pro Tip:</strong> When you are casting to the
                        Apple TV, the volume is controlled by your iPhone.{" "}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer
                className="d-flex align-items-center justify-content-center"
                style={{ border: "none" }}
            >
                <Button
                    className="text-decoration-none text-secondary"
                    variant="link"
                    onClick={handleClose}
                >
                    close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
