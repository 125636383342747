import { useState } from "react";
import {
    Badge,
    Button,
    ListGroup,
    ListGroupItem,
    OverlayTrigger,
    ProgressBar,
    Spinner,
    Tooltip,
} from "react-bootstrap";
import { Flipped, Flipper } from "react-flip-toolkit";
import { useWindowSize } from "usehooks-ts";
import { getEpisode } from "../functions/storage";
import { updateUser } from "../functions";

interface Props {
    show: string;
    episodes: string[];
    showWatched: boolean;
    userId: string;
    watchedEpisodes: string[];
    setWatchedEpisodes: (e: string[]) => void;
}
export default function EpisodeList({
    show,
    episodes,
    showWatched,
    userId,
    watchedEpisodes,
    setWatchedEpisodes,
}: Props) {
    const [downloading, setDownloading] = useState<string[]>([]);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [currentDownloadTask, setCurrentDownloadTask] =
        useState<XMLHttpRequest>();
    const [markingAsWatched, setMarkingAsWatched] = useState("");
    const { width } = useWindowSize();

    async function getFile(path: string | undefined) {
        try {
            if (!path) {
                throw new Error("No key!");
            }
            setDownloadProgress(0)
            setDownloading([path]);
            await getEpisode(path, setDownloadProgress, setCurrentDownloadTask);
            setDownloading([]);
            setDownloadProgress(0)
            setCurrentDownloadTask(undefined)
        } catch (error: any) {
            console.warn(error);
            console.log(error.code);
        }
    }
    function getTypeBadge(file: string) {
        return width < 576 ? (
            <small>
                <Badge className="ms-3 text-dark" bg="info">
                    {file.slice(-3)}
                </Badge>
            </small>
        ) : (
            <Badge className="ms-3 text-dark" bg="info">
                {file.slice(-3)}
            </Badge>
        );
    }
    function renderFiles() {
        const filtered = episodes.filter((item) => item.includes(show));
        if (filtered.length === 0) {
            return (
                <ListGroupItem className="d-flex w-100 justify-content-center text-body-secondary">
                    <small>
                        {showWatched
                            ? "You haven't watched any episodes yet"
                            : "You've watched every episode"}
                    </small>
                </ListGroupItem>
            );
        }
        return filtered.map((episode) => (
            <Flipped key={episode} flipId={episode}>
                <ListGroup.Item className="d-flex align-items-center justify-content-between w-100 p-0">
                    <div className="flex-grow-1 d-flex align-items-center">
                        {!downloading.includes(episode) && (
                            <div className="d-flex align-items-center">
                                <span
                                    style={{
                                        fontSize: width < 576 ? 14 : 16,
                                    }}
                                >
                                    {episode
                                        ?.split("/")[1]
                                        .slice(0, -4)
                                        .replaceAll("_", " ")}
                                </span>
                                {width > 425 && (
                                    <span>{getTypeBadge(episode)}</span>
                                )}
                            </div>
                        )}
                        {downloading.includes(episode) && (
                            <ProgressBar
                                className={`flex-grow-1`}
                                animated
                                now={downloadProgress}
                                label={`${downloadProgress}%`}
                            />
                        )}
                    </div>
                    <div className="d-flex align-items-center">
                        {downloading.includes(episode) &&
                        currentDownloadTask ? (
                            <Button
                                className="px-0 ms-3"
                                onClick={() => {
                                    if (currentDownloadTask) {
                                        currentDownloadTask.abort();
                                        setCurrentDownloadTask(undefined);
                                        setDownloadProgress(0);
                                        setDownloading([]);
                                    }
                                }}
                                variant="link"
                            >
                                <i className="text-warning bi bi-x-octagon" />
                            </Button>
                        ) : (
                            <Button
                                className="px-0 ms-3"
                                onClick={() => getFile(episode)}
                                disabled={downloading.length > 0}
                                variant="link"
                            >
                                <i className="bi bi-cloud-arrow-down" />
                            </Button>
                        )}
                        {downloading.length === 0 && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip>
                                        Mark as{" "}
                                        {showWatched ? "Unwatched" : "Watched"}{" "}
                                    </Tooltip>
                                }
                                placement={width < 576 ? "left" : "top"}
                                rootClose
                                trigger={["hover", "click", "focus"]}
                            >
                                <Button
                                    className="px-0 ms-3"
                                    onClick={() => handleMarkAsWatched(episode)}
                                    // disabled={downloading.length > 0}
                                    variant="link"
                                >
                                    {markingAsWatched === episode ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        <i
                                            className={`bi ${
                                                showWatched
                                                    ? "bi-eye-slash"
                                                    : "bi-eye"
                                            }`}
                                        />
                                    )}
                                </Button>
                            </OverlayTrigger>
                        )}
                    </div>
                </ListGroup.Item>
            </Flipped>
        ));
    }
    async function handleMarkAsWatched(key: string | undefined) {
        try {
            if (!key) {
                throw new Error("No key!");
            }
            if (!userId) {
                throw new Error("No username!");
            }
            setMarkingAsWatched(key);
            let updatedWatchedEpisodes: string[] = [];
            if (showWatched) {
                updatedWatchedEpisodes = watchedEpisodes?.filter(
                    (item) => item !== key
                );
            } else {
                updatedWatchedEpisodes = [...watchedEpisodes, key];
            }
            const payload = {
                watchedEpisodes: updatedWatchedEpisodes,
            };
            updateUser(userId, payload);
            setWatchedEpisodes(updatedWatchedEpisodes);
            setMarkingAsWatched("");
        } catch (error) {
            setMarkingAsWatched("");
            console.warn(error);
        }
    }
    return (
        <Flipper flipKey={episodes}>
            <ListGroup
                className="w-100"
                // style={{ maxWidth: 500 }}
                variant="flush"
            >
                {renderFiles()}
            </ListGroup>
        </Flipper>
    );
}
